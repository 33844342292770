<template>
  <div>

    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item label="发布时间">
        <el-date-picker v-model="dataForm.timeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          align="right">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="所属IP" prop="virtualIps">
        <el-select clearable filterable v-model="dataForm.virtualIps" multiple placeholder="全部">
          <el-option v-for="item in virtualIps" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="性别" prop="gender">
        <el-select multiple v-model="dataForm.genders" clearable placeholder="全部">
          <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="敏感词类型" prop="types">
        <el-select clearable filterable v-model="dataForm.types" multiple placeholder="请选择">
          <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getDataList()" type="primary">查询</el-button>
        <el-button @click="refresh()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button @click="addOrUpdateHandle()" type="primary">新增</el-button>
    <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%">
      <el-table-column prop="mainId" header-align="center" align="center" label="任务id" width="180px">
      </el-table-column>
      <el-table-column prop="nickName" header-align="center" align="center" label="变更角色" width="180px">
      </el-table-column>
      <el-table-column prop="virtualIp" header-align="center" align="center" label="所属IP" width="180px">
      </el-table-column>
      <el-table-column prop="gender" header-align="center" align="center" label="性别">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.gender == 1" type="success">男</el-tag>
            <el-tag v-else-if="scope.row.gender == 0">保密</el-tag>
            <el-tag v-else-if="scope.row.gender == 2">女</el-tag>
            <el-tag v-else type="danger"></el-tag>
          </div>
        </template>
      </el-table-column>


      <el-table-column width="200px" prop="typeName" header-align="center" align="center" label="敏感词类型">
      </el-table-column>
      <el-table-column prop="updatePersonName" header-align="center" align="center" width="200px" label="生成prompt内容">
        <template slot-scope="scope">
          <el-tag style="margin-top: 10px" :key="tag" v-for="tag in JSON.parse(scope.row.promptsStr)"
            :disable-transitions="false">
            {{ tag }}
          </el-tag>

        </template>
      </el-table-column>
      <el-table-column prop="updatePersonName" header-align="center" align="center" label="操作人">
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" width="200px" label="创建时间">
      </el-table-column>

      <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.mainId)">查看详情
          </el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>


    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>

import AddOrUpdate from "./sensitiveInterceptEffect-add-or-update";
import { pickerOptions, genderList } from "@/views/common/commonOpt";

export default {
  data() {
    return {
      virtualIps: [],
      types: [],
      addOrUpdateVisible: true,
      dataForm: {
        operationPerson: '',
        timeArr: []
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      pickerOptions: pickerOptions,
      genderList: genderList,
    }
  },
  components: {
    AddOrUpdate
  },
  computed: {
    timeDefault() {
      var date = new Date();
      return date.getFullYear() + "-" + (date.getMonth() < 10 ? '0' : '') + (date.getMonth() + 1) + "-" + (date.getDate());
    }
  },
  mounted() {
    // 初始化查询，默认为当天
    this.dataForm.timeArr = [this.timeDefault + ' 00:00:00', this.timeDefault + ' 23:59:59']
  },
  activated() {
    this.fillVirtualIps()
    this.fillTypes()
    this.getDataList()
  },
  created() {

  },
  methods: {
    fillTypes() {
      this.$http({
        url: this.$http.adornUrl(`/admin/sensitive/types`),
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.types = data.result
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    fillVirtualIps() {
      this.$http({
        url: this.$http.adornUrl(`/admin/common/virtualIps`),
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.virtualIps = data.result
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    refresh() {
      this.dataForm = {
        operationPerson: '',
        timeArr: []
      }
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/sensitive/page'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          startTime: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[0] : null,
          endTime: (this.dataForm.timeArr && this.dataForm.timeArr.length > 0) ? this.dataForm.timeArr[1] : null,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.$message.error(data.msg)
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
  }
}
</script>
<style lang="scss" scoped>
.el-tag {
  white-space: normal;
  height: auto;
}
</style>
