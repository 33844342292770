<template>
  <el-dialog width="80%" title="敏感词拦截配置" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" label-width="120px">

      <div style="display: flex">
        <el-form-item label="所属IP" prop="virtualIps">
          <el-select clearable filterable v-model="dataForm.virtualIps" multiple placeholder="全部">
            <el-option v-for="item in virtualIps" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="性别" prop="gender">
          <el-select multiple v-model="dataForm.genders" clearable placeholder="全部">
            <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="角色" prop="virtualUids">
          <el-select clearable filterable v-model="dataForm.virtualUids" multiple placeholder="全部">
            <el-option v-for="item in virtuals" :key="item.uid" :label="item.nickname" :value="item.uid + ''">
            </el-option>
          </el-select>
        </el-form-item>
      </div>



      <el-form-item label="敏感词类型" prop="types" :rules="{
        required: true, message: '敏感词类型不能为空', trigger: 'blur'
      }">
        <el-select clearable filterable v-model="dataForm.types" multiple placeholder="请选择">
          <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-for="(item, index) in dataForm.prompts" :key="index" :label="'prompt' + (index + 1) + ': '"
        :prop="'prompts[' + index + ']'" :rules="{
          required: true, message: 'prompt不能为空', trigger: 'blur'
        }">
        <el-input style="width: 40%" type="textarea" v-model="dataForm.prompts[index]"></el-input>
        <el-button v-if="dataForm.prompts.length > 1" type="danger" @click.prevent="removePrompt(index)">删除</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="addPrompt" type="success" :disabled="dataForm.prompts.length >= 20">继续添加</el-button>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">{{ dataForm.id > 0 ? '保存' : '新建' }}</el-button>
      <el-button v-if="dataForm.id > 0" type="danger" @click="deleteForm">删除配置</el-button>
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { genderList } from "@/views/common/commonOpt";

const sourceDataForm = {
  id: 0,
  virtualIps: [],
  genders: [],
  virtualUids: [],
  types: [],
  prompts: [""]
}
export default {
  data() {
    return {
      types: [],
      virtualIps: [],
      virtuals: [],
      ossUploadUrl: "",
      visible: false,
      roleList: [],
      dataForm: Object.assign(sourceDataForm),
      genderList: genderList,
    }
  },
  created() {
    this.fillVirtualIps()
    this.fillVirtuals()
    this.fillTypes()
  },
  methods: {
    init(id) {
      this.dataForm = { ...sourceDataForm }
      this.dataForm.id = id || 0
      this.$nextTick(() => {
        if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id === 0) {
        // 新增模式
        this.visible = true
        return
      }
      this.$http({
        url: this.$http.adornUrl(`/admin/sensitive/detail`),
        method: 'get',
        params: this.$http.adornParams({ id: this.dataForm.id })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.visible = true
          this.dataForm.id = data.result.id
          if (data.result.virtualIps) this.dataForm.virtualIps = data.result.virtualIps
          if (data.result.genders) this.dataForm.genders = data.result.genders
          if (data.result.virtualUids) this.dataForm.virtualUids = data.result.virtualUids
          if (data.result.types) this.dataForm.types = data.result.types
          if (data.result.prompts) this.dataForm.prompts = data.result.prompts
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    fillTypes() {
      this.$http({
        url: this.$http.adornUrl(`/admin/sensitive/types`),
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.types = data.result
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    fillVirtuals() {
      this.$http({
        url: this.$http.adornUrl(`/admin/virtual/all`),
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.virtuals = data.result
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    fillVirtualIps() {
      this.$http({
        url: this.$http.adornUrl(`/admin/common/virtualIps`),
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.virtualIps = data.result
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    requestSubmit() {
      this.$http({
        url: this.$http.adornUrl(`/admin/sensitive/${this.dataForm.id > 0 ? 'update' : 'save'}`),
        method: 'post',
        data: this.dataForm
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false
            }
          })
          this.$emit('refreshDataList')
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err);
      })
    },
    submitForm() {
      console.log('-----')
      console.log(this.dataForm)
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$confirm('本次调整将影响角色的全站反馈影响较大，当前修改是否已确认无误？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.requestSubmit();
          })
        }
      })
    },
    requestDelete() {
      this.$http({
        url: this.$http.adornUrl(`/admin/sensitive/delete`),
        method: 'delete',
        params: { id: this.dataForm.id }
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false
            }
          })
          this.$emit('refreshDataList')
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err);
      })
    },
    deleteForm() {
      console.log('-----')
      console.log(this.dataForm)
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$confirm('本次调整将影响角色的全站反馈影响较大，是否确认删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.requestDelete();
          })
        }
      })

    },
    removePrompt(index) {
      this.dataForm.prompts.splice(index, 1)
    },
    addPrompt() {
      this.dataForm.prompts.push("")
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>